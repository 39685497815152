import React, { useState, useRef, useEffect } from "react";
import axios from '../../../../axios';

import styled, { css } from "styled-components";

import { useSBContext } from "../../../../utils/snackbar";

import { Chip, IconButton, TextField, Button, LinearProgress, CircularProgress, Divider, MenuItem, Checkbox, FormControlLabel } from "@material-ui/core";

import AddRoundedIcon from '@material-ui/icons/AddRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';


import OutlinedSelect from "../../../../utils/OutlinedSelect";

import { theme } from "../../../../utils/theme";


import Dialog from "../../../../utils/dialog";

import PanelForm from "../../PanelForm";

import { FullDivider, BasicFlex, IconBox, NoValues } from "../../../../utils/common";
import { set } from "lodash";

const Container = styled.div`
`;

const Sections = styled.div`

`

const SettingsBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 1rem;
`;

const AdditionalOptions = styled.div`
  display: flex;
  flex-direction: column;

`;

const Section = styled.section`
  /* margin-bottom: 6rem; */
  margin: 1rem 0;
  margin-bottom: 3rem;
`

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const BottomRow = styled.div`
  display: flex;
  margin-top: 3rem;
`;

const BottomRowRight = styled(BottomRow)`
  justify-content: flex-end;
`;

const SectionHeader = styled.h5`
  font-size: 1.4rem;
  font-weight: 600;
  text-transform: uppercase;
  color: ${props => props.theme.colors.lightText};
`;

const ChipArray = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  margin: 1rem 0;
  margin-bottom: 3rem;
`

const PriceItemRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  justify-content: space-between;
  margin: 15px auto;
`;

const PriceItem = styled.div`
  padding: 1.3rem;
  position: relative;
  flex-grow: 1;
  border-radius: 3px;
  border: 1px solid #0000001f;
  border-left: 3px solid ${props => props.theme.colors.secondaryMain};
  display: flex;
  justify-content: space-between;

  ${props => props.err && css`
    border-left: 3px solid ${props.theme.colors.error};
  `}
  ${props => props.dup && css`
    border-left: 3px solid ${props.theme.colors.caution};
  `}
`

const IconContainer = styled.div`
  width: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Rows = styled.section`
  min-height: 5rem;
  max-height: 30rem;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0.5rem;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${props => props.theme.colors.lightTextGray};
    border-radius: 1rem;
  }
  &::-webkit-scrollbar-track {
    background: ${props => props.theme.colors.border};
  }
`

const SelectItem = styled.div`
  min-width: 16rem;
  margin-right: 2rem;
`;

const PriceInput = styled(TextField)`
  width: 20rem;
`

const NoValuesText = styled.p`
  font-size: 1.4rem;
  font-weight: 500;
  color: ${props => props.theme.colors.lightText};
  margin: 1rem 0;
`;

const BrandSelect = styled.div`
  width: 20rem;

  .title {
    display: block;
    font-size: 1.2rem;
    font-weight: 600;
    color: ${props => props.theme.colors.lightText};
    margin-bottom: .6rem;
  }
`;

const Warning = styled.p`
  font-size: 1.6rem;
  /* font-weight: 500;; */
`;


const PricesSettings = (props) => {

  const field = useRef("");

  const [open, setOpen] = useState(false);
  const [brands, setBrands] = useState([]);
  const [brand, setBrand] = useState("");
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const [includeMaterial, setIncludeMaterial] = useState(false);
  const [includeColor, setIncludeColor] = useState(false);

  const [values, setValues] = useState({
    materials: [],
    lengths: []
  });

  const [prices, setPrices] = useState({
    cost: [],
    wholesale: []
  });

  const [errors, setErrors] = useState({});

  const addRow = (priceSchema) => {
    let rand = Math.random();
    setPrices(prev => {
      prev[priceSchema].push({length: "", material: "", color: "", [priceSchema]: "", rand});
      return {
        ...prev
      }
    })
  }

  const removeRow = (i, priceSchema) => {
    setPrices(prev => {
      prev[priceSchema].splice(i, 1);
      return {
        ...prev
      }
    })
    let {valid, errors} = validateValues(prices);
    console.log(errors, "errors")
    setErrors(errors);
  }

  const handleChange = (e, i, priceSchema, label) => {
    const { value } = e.target;
    setPrices(prev => {
      let itemIndex = prev[priceSchema].findIndex((item, index) => index === i);
      prev[priceSchema][itemIndex][label] = value;
      return {
      ...prev
      }
    })
    let objName = `${priceSchema}${i}`;
    updateErrorItem(objName);
  }

  const updateErrorItem = (objName) => {
    setErrors(prev => {
      prev[objName] = {};
      return {
        ...prev
      }
    })
  }

  const { handleClick } = useSBContext();

  const loadData = async () => {
    setLoading(true);
    try {
      // fetch prices
    const { data: priceValues } = await axios.get(`/system_settings/pricing?brand=${encodeURIComponent(brand)}`)

    if (priceValues) {
      const { cost, wholesale, includeMaterial, includeColor } = priceValues.settings;
      setPrices({cost, wholesale});
      setIncludeMaterial(includeMaterial);
      setIncludeColor(includeColor);
    } else {
      setPrices({
        cost: [],
        wholesale: []
      })
      setIncludeMaterial(false);
      setIncludeColor(false);
    }

    // on first load, fetch brands and system values
    if (!brands.length) {
      // fetch brands
      const { data: brandList } = await axios.get('/brands/names')
      // fetch values
      const { data: systemValues } = await axios.get('/system_settings/system_values')

      const { data: tagList } = await axios.get('/tags/')
      const brandNames = brandList.map(el => el.name)
      setBrands(brandNames)
      setBrand(brandList.find(el => el.isDefault).name)

      console.log(systemValues, "systemValues")

      setValues({
        materials: systemValues.settings.materials,
        lengths: systemValues.settings.lengths,
        colors: tagList
      })
    }
    } catch (e) {
      handleClick("error", "Error fetching data");
    } finally {
      setLoading(false);
    }
    
  }

  useEffect(() => {
    loadData();
  }, [brand])


  const checkItems = (values, priceModel, fieldName) => {
    let errors = {};
    let duplicates = {};
    let valid = true;
    values.forEach((item, i) => {
      errors[`${priceModel}${i}`] = {};
      if (!item.length) {
        valid = false;
        errors[`${priceModel}${i}`] = {msg: "Length is required"};
      } else if (!item[fieldName]) {
        valid = false;
        errors[`${priceModel}${i}`] = {msg: "Cost is required"};
      } else if (!item.material && includeMaterial) {
        valid = false;
        errors[`${priceModel}${i}`] = {msg: "Material is required"};
      } else if (!item.color && includeColor) {
        valid = false;
        errors[`${priceModel}${i}`] = {msg: "Color is required"};
      }
      
      let itemName = `${priceModel}${item.length}${item.material}${item.color}`;
      console.log(itemName, "itemName")
      if (duplicates[itemName]?.name) {
        valid = false;
        errors[`${priceModel}${i}`].dup = true;
        errors[`${priceModel}${duplicates[itemName].index}`].dup = true;
      } else {
        duplicates[itemName] = {name: itemName, index: i};
      }
    })
    console.log(errors, "errors")
    return {valid, errors};
  }

  const validateValues = (priceValues) => {
    let errors = {};
    let valid = true;
    let checkCosts = checkItems(priceValues.cost, "cost", "cost");
    errors = {...errors, ...checkCosts.errors};
    valid = checkCosts.valid;
    let checkWholesale = checkItems(priceValues.wholesale, "wholesale", "wholesale");
    errors = {...errors, ...checkWholesale.errors};
    valid = !valid ? valid : checkWholesale.valid;
    
    return {valid, errors};
  }

  const submitHandler = async (props, update = false) => {
    let {valid, errors} = validateValues(prices);
    setErrors(errors);
    if (!valid) {
      handleClick("error", "Please fill in all required fields, and ensure there are no duplicate values");
      return;
    }
    try {
      setSubmitLoading(true);
      let updatePrices = update ? true : false;
      console.log(updatePrices, update, "update")
      const { data: savedPrices } = await axios.post('/system_settings/pricing', {brand, settings: {...prices, includeMaterial, includeColor}, updatePrices})
      handleClick("success", "Prices saved successfully");
    } catch (e) {
      handleClick("error", "Error saving prices");
    } finally {
      setSubmitLoading(false);
    }
  }

  const updateExistingPrices = async () => {
    await submitHandler(null, true);
    return "updated";
  }

  return (
    <Container>
     
      <PanelForm title="Inventory Prices">
        {loading ? <LinearProgress /> :
        <Sections>
          <SettingsBar>
            <AdditionalOptions>
              <FormControlLabel
                control={<Checkbox 
                onChange={(e) => setIncludeMaterial(e.target.checked)} 
                checked={includeMaterial}
                color="primary" />}
                label="Include Material"
              />
              <FormControlLabel
                control={<Checkbox 
                onChange={(e) => setIncludeColor(e.target.checked)} 
                checked={includeColor}
                color="primary" />}
                label="Include Color"
              />
            </AdditionalOptions>
            {brands.length > 1 ? <BrandSelect>
            <span className="title">Select Brand</span>
            <OutlinedSelect size="small" 
              color={theme.colors.secondary75} 
              bgColor={theme.colors.secondary75}
              textColor={theme.colors.secondary25}
              button
              val={brand} 
              onChange={(e) => setBrand(e.target.value)}
            >
              {brands.map(el => <MenuItem key={el} value={el}>{el}</MenuItem>)}
            </OutlinedSelect>
            </BrandSelect> : null }
          </SettingsBar>
          <Divider light />
          <Section>
            <HeaderRow>
             <SectionHeader>Cost Values</SectionHeader>
             <Button variant="outlined" color="primary" startIcon={<AddRoundedIcon />} onClick={() => addRow("cost")}>Add Cost Value</Button>
            </HeaderRow>

            <Rows>
              {
                prices.cost.length ?
                prices.cost.map(({length, material, cost, rand}, i) => {
                  return (
                    <PriceItemRow key={rand}>
                    <PriceItem err={errors[`cost${i}`]?.msg} dup={errors[`cost${i}`]?.dup}>
                      <BasicFlex justify="flex-start">
                        <SelectItem>
                          <OutlinedSelect
                            size="small" color={theme.colors.headerBg} 
                            bgColor={theme.colors.headerBg}
                            textColor={theme.colors.secondary25}
                            placeholder="Length"
                            onChange={(e) => handleChange(e, i, "cost", "length")}
                            label="Length"
                            val={prices.cost[i].length}
                          >
                            {values.lengths.map((el, ind) => <MenuItem key={el.value} value={el.value}>{el.value}</MenuItem>)}
                          </OutlinedSelect>
                        </SelectItem>
                       {includeMaterial && <SelectItem>
                        <OutlinedSelect
                            size="small" color={theme.colors.headerBg} 
                            bgColor={theme.colors.headerBg}
                            textColor={theme.colors.secondary25}
                            label="Material"
                            onChange={(e) => handleChange(e, i, "cost", "material")}
                            val={prices.cost[i].material}
                          >
                            {values.materials.map((el) => <MenuItem key={el.value} value={el.value}>{el.value}</MenuItem>)}
                          </OutlinedSelect>
                        </SelectItem> }
                       {includeColor && <SelectItem>
                        <OutlinedSelect
                            size="small" color={theme.colors.headerBg} 
                            bgColor={theme.colors.headerBg}
                            textColor={theme.colors.secondary25}
                            label="Color Category"
                            onChange={(e) => handleChange(e, i, "cost", "color")}
                            val={prices.cost[i].color}
                          >
                            {values.colors.map((el) => <MenuItem key={el.name} value={el.name}>{el.name}</MenuItem>)}
                          </OutlinedSelect>
                        </SelectItem> }
                      </BasicFlex>
                      <PriceInput label="Cost" variant="outlined" size="small" type="number"
                        onChange={(e) => handleChange(e, i, "cost", "cost")}
                        value={prices.cost[i].cost}
                      />
                    </PriceItem>
                    <IconContainer>
                      <IconBox bg={theme.colors.iconBgRed} color={theme.colors.iconRed} button onClick={() => removeRow(i, "cost")} size="medium">
                        <DeleteRoundedIcon />
                      </IconBox>
                    </IconContainer>
                    </PriceItemRow>
                  )
                })
                : <NoValues>
                  <NoValuesText>There are currently no cost values</NoValuesText>
                  </NoValues>
              }
               
            </Rows>
            
          </Section>

          <Divider light />

          <Section>
            <HeaderRow>
             <SectionHeader>Wholesale Price Values</SectionHeader>
             <Button variant="outlined" color="primary" startIcon={<AddRoundedIcon />} onClick={() => addRow("wholesale")}>Add Price Value</Button>
            </HeaderRow>


            <Rows>
            {
                prices.wholesale.length ?
                prices.wholesale.map(({length, material, wholesale, rand}, i) => {
                  return (
                    <PriceItemRow key={rand}>
                    <PriceItem err={errors[`wholesale${i}`]?.msg} dup={errors[`wholesale${i}`]?.dup}>
                      <BasicFlex justify="flex-start">
                        <SelectItem>
                        <OutlinedSelect
                            size="small" color={theme.colors.headerBg} 
                            bgColor={theme.colors.headerBg}
                            textColor={theme.colors.secondary25}
                            label="Length"
                            onChange={(e) => handleChange(e, i, "wholesale", "length")}
                            val={prices.wholesale[i].length}
                          >
                             {values.lengths.map((el, ind) => <MenuItem key={el.value} value={el.value}>{el.value}</MenuItem>)}
                          </OutlinedSelect>
                        </SelectItem>
                      {includeMaterial && <SelectItem>
                          <OutlinedSelect
                            size="small" color={theme.colors.headerBg} 
                            bgColor={theme.colors.headerBg}
                            textColor={theme.colors.secondary25}
                            label="Material"
                            onChange={(e) => handleChange(e, i, "wholesale", "material")}
                            val={prices.wholesale[i].material}
                          >
                             {values.materials.map((el) => <MenuItem key={el.value} value={el.value}>{el.value}</MenuItem>)}
                          </OutlinedSelect>
                        </SelectItem> }
                        {includeColor && <SelectItem>
                        <OutlinedSelect
                            size="small" color={theme.colors.headerBg} 
                            bgColor={theme.colors.headerBg}
                            textColor={theme.colors.secondary25}
                            label="Color Category"
                            onChange={(e) => handleChange(e, i, "wholesale", "color")}
                            val={prices.wholesale[i].color}
                          >
                            {values.colors.map((el) => <MenuItem key={el.name} value={el.name}>{el.name}</MenuItem>)}
                          </OutlinedSelect>
                        </SelectItem> }
                      </BasicFlex>
                      <PriceInput 
                        label="Wholesale Price" 
                        variant="outlined" 
                        size="small" 
                        type="number"
                        onChange={(e) => handleChange(e, i, "wholesale", "wholesale")} 
                        value={prices.wholesale[i].wholesale}
                        />
                    </PriceItem>
                    <IconContainer>
                    <IconBox bg={theme.colors.iconBgRed} color={theme.colors.iconRed} size="medium" onClick={() => removeRow(i, "wholesale")}>
                      <DeleteRoundedIcon />
                    </IconBox>
                  </IconContainer>
                  </PriceItemRow>
                  )
                })
                : <NoValues>
                  <NoValuesText>There are currently no wholesale price values</NoValuesText>
                  </NoValues>
              }
            </Rows>
            
          </Section>
        
        </Sections> }

        <Divider light />

        <BottomRowRight>
          <Dialog
            title={`Modify existing inventory prices`}
            onSave={() => updateExistingPrices()}
            save="Update Inventory"
            size="md"
            toggle={
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<EditRoundedIcon />}
            >
              Update Current Inventory Prices
            </Button>}
          >
           <BasicFlex gap="3rem"> <WarningRoundedIcon htmlColor={theme.colors.caution} fontSize="large" /> <Warning>
              This action will update all existing inventory prices with the new values, including items on consignment. Only wholesale prices, not costs. Please ensure you have reviewed the new prices before proceeding.
            </Warning> </BasicFlex>
          </Dialog>
        </BottomRowRight>

        <BottomRow>
          <Button variant="contained" startIcon={<SaveOutlinedIcon />} color="primary" onClick={submitHandler}>{submitLoading ? <CircularProgress color="white" size={24} /> : "Save Inventory Prices"}</Button>
        </BottomRow>
      </PanelForm> 
    </Container>
  )
}

export default PricesSettings;