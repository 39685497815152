import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function FormDialog({toggle, title, text, children, onSave, save, shutDown = false, forceOpen=false, forceClose=() => {}, size = 'md', cancel = "Cancel", ...props}) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (!open) forceClose()
    setOpen(false);
  };

  const handleSave = async () => {
    const saved = await onSave();
    // console.log(saved, "ccccccc");
    if (!saved) return;
    if (!open) forceClose()
    setOpen(false);
  };

  useEffect(() => {
    if (shutDown) {
      console.log("shutDown useEffect");
      setOpen(false);
      forceOpen = false;
    }
  }, [shutDown])

  return (
    <div>
      <div onClick={handleClickOpen}>{toggle}</div>
      <Dialog {...props} maxWidth={size} fullWidth open={open || forceOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {text}
          </DialogContentText>
         {children}
        </DialogContent>
        <DialogActions>
          {cancel && <Button type="button" onClick={handleClose} color="primary">
            {cancel}
          </Button>
          }
          <Button type="button" onClick={handleSave} color="primary">
            {save}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}